export default (): Ref<boolean> => {
  const { y: yScroll } = useWindowScroll()
  const prevYScroll = ref(yScroll.value)
  const isScrollingUp = ref(false)

  watch(yScroll, (newY) => {
    isScrollingUp.value = newY < prevYScroll.value
    prevYScroll.value = newY
  })

  return isScrollingUp
}
