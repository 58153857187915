import { defineStore } from 'pinia'

export const useNavigationMenuStateStore = defineStore('navigationMenuState', () => {
  const openMegaMenuItem = ref<SubMenuItemId | null>(null)
  const openMobileMenuSubItem = ref<SubMenuItemId | null>(null)
  const isLangMenuOpen = ref(false)
  const isMobileMenuOpen = ref(false)

  const setOpenMegaMenuItem = (subMenuItem: SubMenuItemId | null) => {
    openMegaMenuItem.value = openMegaMenuItem.value === subMenuItem ? null : subMenuItem
  }

  const setOpenMobileMenuSubItem = (subMenuItem: SubMenuItemId | null) => {
    openMobileMenuSubItem.value = openMobileMenuSubItem.value === subMenuItem ? null : subMenuItem
  }

  const setLangMenuState = (isOpen: boolean) => {
    isLangMenuOpen.value = isOpen
  }

  const setMobileMenuState = (isOpen: boolean) => {
    isMobileMenuOpen.value = isOpen
  }

  const closeAllDesktopMenus = () => {
    isLangMenuOpen.value = false
    openMegaMenuItem.value = null
  }

  return {
    openMegaMenuItem,
    openMobileMenuSubItem,
    isLangMenuOpen,
    isMobileMenuOpen,
    setOpenMegaMenuItem,
    setOpenMobileMenuSubItem,
    setLangMenuState,
    setMobileMenuState,
    closeAllDesktopMenus,
  }
})
