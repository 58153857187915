import type { LocaleObject } from '@nuxtjs/i18n'
import consts from '@/consts'
export default (loc: LocaleObject): string => {
  /**
   * This is producing a warning when navigating from a route that has a dynamic parameter.
   * [Vue Router warn]: Discarded invalid param(s) "slug" when navigating.
   */
  const switchLocalePath = useSwitchLocalePath()
  const slug = getSlugWithoutHash(
    switchLocalePath(typeof loc === 'string' ? loc : loc?.code),
  ).replace(`/${consts.articleNotFoundUrlPart}`, '')

  return slug
}
