<script setup lang="ts">
import { useNavigationMenuStateStore } from '@/store/navigationMenuState'

const toggleMobileNav = () => {
  const isMobileMenuOpen = useNavigationMenuStateStore().isMobileMenuOpen
  if (!isMobileMenuOpen) {
    useNavigationMenuStateStore().setOpenMobileMenuSubItem(null)
  }
  useNavigationMenuStateStore().setMobileMenuState(!isMobileMenuOpen)
}
</script>

<template>
  <button
    class="mobile-burger"
    :class="`mobile-burger--${useNavigationMenuStateStore().isMobileMenuOpen ? 'open' : 'closed'}`"
    :aria-label="$t('aria_label_toggle_mobile_menu')"
    aria-haspopup="true"
    :aria-expanded="useNavigationMenuStateStore().isMobileMenuOpen"
    aria-controls="mobile-menu-id"
    :title="$t('aria_label_toggle_mobile_menu')"
    @click="toggleMobileNav"
  >
    <div class="mobile-burger__inner">
      <span />
      <span />
      <span />
    </div>
  </button>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';

.mobile-burger {
  position: relative;
  .button-reset();
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--green-5);

  &:focus {
    box-shadow: 0 0 0 3px var(--grey-5);
  }

  &__inner {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #fff;
    height: 100%;

    > span {
      transition: 0.25s all;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      display: block;
      height: 2px;
      width: 22px;
      background-color: var(--grey-1);
      border-radius: 99px;
      opacity: 1;

      &:nth-child(1) {
        top: 7px;
        .mobile-burger--open & {
          top: calc(50% - 1px);
          transform: translateX(-50%) rotate(45deg);
        }
      }
      &:nth-child(2) {
        top: 15px;

        .mobile-burger--open & {
          opacity: 0;
        }
      }
      &:nth-child(3) {
        top: 23px;

        .mobile-burger--open & {
          top: calc(50% - 1px);
          transform: translateX(-50%) rotate(-45deg);
        }
      }
    }
  }
}
</style>
