import consts from '@/consts'
export default function () {
  const route = useRoute()

  // In most cases we do not want to have query params in the canonical URL.
  let queryParamsForCanonicalUrl = ''

  consts.queryParamsThatShouldBePartOfCanonicalUrl.forEach((qp) => {
    if (route.query[qp] !== undefined) {
      queryParamsForCanonicalUrl += qp + '=' + route.query[qp]
    }
  })

  if (queryParamsForCanonicalUrl.length > 0) {
    queryParamsForCanonicalUrl = '?' + queryParamsForCanonicalUrl
  }

  useHead(() => ({
    link: [
      {
        rel: 'canonical',
        href: getUrlWithoutTrailingSlash(consts.baseUrl + route.path + queryParamsForCanonicalUrl),
      },
    ],
  }))
}
